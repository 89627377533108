///////*From 2016 Renew*///////

	//SPMENU
	$('#spMenu').on('click',function(){
		if($(this).is(":animated")){
			return false;
		} else{
			$(this).toggleClass('open');
			$('#headerWrapper,#header').toggleClass('open');
			if($('#headerWrapper').hasClass('open')) {
				$("#headerWrapper").scrollTop(0);
			}
		}
	});

	//HOME SLIDER
	$(window).on('load resize', function(){
		var w = window.outerWidth;
		if(w > 640){
			//eventpanel -パネルが4つに満たない時はcomingsoonパネルを自動表記
			$(function(){
				$('.comingSoon').remove();
				var panelWrap = '#caruselE';
				var panelsuu = $(panelWrap+' li').length;
				if(panelsuu < 4){
					for (var step = panelsuu; step < 4; step++) {
						$(panelWrap).append('<li class="comingSoon noTab"></li>');
					}
				}
			});

		}else{

			//eventpanel -パネルが2つに満たない時はcomingsoonパネルを自動表記
			$(function(){
				$('.comingSoon').remove();
				var panelWrap = '#caruselE';
				var panelsuu = $(panelWrap+' li').length;
				if(panelsuu < 3){
					for (var step = panelsuu; step < 3; step++) {
						$(panelWrap).append('<li class="comingSoon noTab"></li>');
					}
				}
			});

			//過去のイベント
			function oldEvent($targetDatePlace){
				$($targetDatePlace).each(function(){
					var eventTxt = $(this).text();
					var eventTxt2 = eventTxt.replace("｜", "<br>");
					$(this).html(eventTxt2);
					//console.log('www');
				});
			};
			oldEvent('#openClass .tabbox .datePlace');
			oldEvent('#event .tabbox .datePlace');

			//高さを揃える
			$('#opinions #mainPanel .col2 li .panelContent dl').matchHeight();
			$('#assessment #mainContent #mainPanel ul li h3 span.sponeRow').matchHeight();
			$('#assessment #mainContent #mainPanel ul li dl dt').matchHeight();

		}
	});


	//aside サブナビ

	function asideTop($idElement){
		var arrayTop = ['a','b'];//配列を用意
		$($idElement).on('click',function(){//#asideをクリックしたら
			var w = window.outerWidth;
			if(w <= 640){
				if($(this).hasClass('open')){ //閉じるときの処理
					var viewtop = arrayTop[0]; //用意された配列arrayTopの先頭を取り出す
					$('body').css({'top':0*-1});//bodyのtopを0に戻す
					setTimeout(function() {
						$("html, body").scrollTop(viewtop);//配列から取り出した値までscroll
					}, 1);
				} else {//aside開く時の処理
					var scrlTop = $('#header').offset().top;//ヘッダーの表示位置の値を取得
					arrayTop.shift() ;//用意された配列arrayTopの先頭の値を消去する(、削除した要素を返す。)
					arrayTop.unshift(scrlTop);//用意された配列arrayTopの先頭に、ヘッダーの表示位置の値を送る
					var viewtop = arrayTop[0]; //用意された配列arrayTopの先頭を取り出す
					$('body').css({'top':viewtop*-1});
				}
				$(this).toggleClass('open');//#asideにopenという名のclassをつけたりとったり
				$('body').toggleClass('asideOpen');//bodyにasideOpenという名のclassをつけたりとったり
			}
		});
	};
	asideTop('#aside');//function asideTopを実行
	asideTop('#newAside');//function asideTopを実行

